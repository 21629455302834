import { awsClientGateway } from "./common/gateway";
import { logMessage as logger } from "../utils/amplifyLogger";
import { clientLog as submitLog } from "./logging";

/**
 * Makes a httpRequest with supplied parameters
 * @param  {string}  pathname               API path to make the http request
 * @param  {string}  method                 HTTP request method
 * @param  {object}  body                   optional object
 * @return {Promise}          response
 */
export const httpRequest = async ({ pathname, method, queryParams, body }) => {
  const queryStringParameters = buildQueryStringParameters(queryParams);

  const requestConfig = {
    endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
    pathTemplate: pathname + queryStringParameters,
    method,
    body,
  };

  try {
    return await awsClientGateway(requestConfig);
  } catch (err) {
    const message = `Http ${method} invoke failed with error : ${err}`;
    // browser console logs
    logger(pathname, message, `error`);
    // submit logs to the server
    submitLog({
      pathname,
      method,
      message,
    });

    throw err;
  }
};

/**
 * Returns a string representing a list of query parameters or an empty string
 * e.g: ?param1=value1&param2=value2
 * e.g: <empty string>
 * @param  {object} queryParams               query params
 * @return {string}             a string
 */
const buildQueryStringParameters = (queryParams) => {
  let queryParamString = "";
  let amper = "";
  let qm = "?";
  if (typeof queryParams === "object") {
    for (const key of Object.keys(queryParams)) {
      queryParamString = `${qm}${queryParamString}${amper}${key}=${queryParams[key]}`;
      amper = "&";
      qm = "";
    }
  }

  return queryParamString;
};
